import React from "react";
import { homeStyles } from "../../styles";

export const HomePage = () => {
  return (
    <div style={homeStyles.container}>
      <h1 style={homeStyles.title}>Delphi Labs</h1>
      <div style={homeStyles.body}>
        {/* Body text */}
        <p>Delphi Labs is a frontier tech research and advisory firm. </p>
        <p>We partner with companies to build and go to market with ambitious applications of new technology.</p>

        <p>Our goal is to play a mission-critical role in the creation of institutions that matter in the human story. </p>

        {/* Links */}
        <br />
        <a style={homeStyles.links} href={"mailto:team@delphilabs.ai"}>
          Email us
        </a>
        <br />
        {/* <p>
          <a style={homeStyles.links} href={"/social-infra"}>
            Building Modern Social Infrastructure
          </a>
        </p> */}
      </div>

    </div>
  );
};
